<template>
  <v-dialog v-model="show" max-width="800px" persistent>
    <v-card>
      <div centered>
        <v-card-title>
          <span class="text-h5" style="color: #182026">{{ titulo }}</span>
        </v-card-title>

        <v-alert v-model="validacaoError" dismissible dense text type="error">{{ mensagemErro }}</v-alert>
        <v-alert v-model="isCndOrCpen" dismissible dense text type="warning">{{ mensagemFile }}</v-alert>

        <div text-align="center">
          <div class="pa-5 form-group">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col v-if="isEdit" sm="5">
                  <v-text-field
                    id="textCnpj"
                    v-model="certidaoToEdit.cnpj"
                    disabled
                    label="CNPJ"
                    outlined
                    persistent-placeholder
                    dense
                    @change="validData()"
                  />
                </v-col>
                <v-col v-if="!isEdit" sm="5">
                  <v-autocomplete
                    id="idACCnpj"
                    v-model="certidaoToEdit.cnpj"
                    label="CNPJ"
                    :items="userEstablishment"
                    item-text="details.cnpj"
                    :item-value="(item) => `${item.details.cnpj}`"
                    :multiple="false"
                    append-icon="mdi-chevron-down"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    class="static"
                    @change="validData()"
                  >
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0">
                        {{ certidaoToEdit.cnpj | cnpj }}
                      </span>
                    </template>
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.details.cnpj | cnpj }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.socialName }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    id="idACTipe"
                    v-model="certidaoToEdit.certificate_type"
                    label="Tipo"
                    :items="tipos"
                    :item-value="(item) => `${item}`"
                    :multiple="false"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    class="static"
                    append-icon="mdi-chevron-down"
                    @change="validData()"
                    @blur="changeTipo()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    id="textCodigo"
                    v-model="certidaoToEdit.code_control"
                    v-mask="'XXXX.XXXX.XXXX.XXXX'"
                    maxlength="19"
                    minlength="19"
                    :counter="19"
                    label="Código de controle"
                    dense
                    outlined
                    :disabled="certidaoToEdit.certificate_type == 'Indisponível'"
                    :style="
                      certidaoToEdit.certificate_type == 'Indisponível'
                        ? 'background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;'
                        : 'background-color: #ffffff; height: 40px !important;'
                    "
                    persistent-placeholder
                    placeholder="obrigatorio"
                    @change="validData()"
                /></v-col>
              </v-row>

              <v-row>
                <v-col sm="4">
                  <v-text-field
                    v-model="certidaoToEdit.time_issuance"
                    v-mask="'##/##/####'"
                    maxlength="10"
                    minlength="10"
                    :counter="10"
                    :label="certidaoToEdit.certificate_type == 'Indisponível' ? 'Data da tentativa' : 'Data da emissão'"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    @change="validData()"
                  />
                </v-col>

                <v-col sm="4">
                  <v-text-field
                    v-model="certidaoToEdit.hour_issuance"
                    v-mask="'##:##:##'"
                    maxlength="8"
                    minlength="8"
                    :counter="8"
                    label="Hora da emissão"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :disabled="certidaoToEdit.certificate_type == 'Indisponível'"
                    :style="
                      certidaoToEdit.certificate_type == 'Indisponível'
                        ? 'background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;'
                        : 'background-color: #ffffff; height: 40px !important;'
                    "
                    @change="validData()"
                  />
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="certidaoToEdit.expiration_date"
                    v-mask="'##/##/####'"
                    maxlength="10"
                    minlength="10"
                    :counter="10"
                    label="Validade"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :disabled="certidaoToEdit.certificate_type == 'Indisponível'"
                    :style="
                      certidaoToEdit.certificate_type == 'Indisponível'
                        ? 'background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;'
                        : 'background-color: #ffffff; height: 40px !important;'
                    "
                    @change="validData()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <v-file-input
                    v-model="certidaoToEdit.cnd_file"
                    :rules="rules1"
                    label="Upload da CND"
                    accept="application/pdf"
                    outlined
                    dense
                    :disabled="certidaoToEdit.certificate_type == 'Indisponível'"
                    :class="{ 'indisponivel-style': certidaoToEdit.certificate_type === 'Indisponível' }"
                    @change="validData()"
                  ></v-file-input>
                </v-col>
                <v-col sm="4">
                  <v-file-input
                    v-model="certidaoToEdit.pendecy_file"
                    :rules="rules2"
                    label="Upload de pendência"
                    accept="application/pdf"
                    outlined
                    dense
                    :disabled="certidaoToEdit.pendencyType == 'FALSE'"
                    :class="{ 'indisponivel-style': certidaoToEdit.pendencyType == 'FALSE' }"
                    @change="validData()"
                  ></v-file-input>
                </v-col>
                <v-col sm="4">
                  <v-combobox
                    v-model="certidaoToEdit.pendencyType"
                    :items="pendencias"
                    label="Pendências"
                    dense
                    outlined
                    @change="validData()"
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item | pendency }}
                    </template>

                    <template slot="selection" slot-scope="data">
                      <v-tooltip v-if="data.item === 'UNAVAILABLE'" right color="grey darken-2">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ data.item | pendency }} <v-icon small>mdi-information-outline</v-icon>
                          </span>
                        </template>
                        <span class="tooltipUnavailable"
                          >O plano contratado desta empresa <br />
                          não contempla a funcionalidade de <br />
                          análise de pendências</span
                        >
                      </v-tooltip>
                      <span v-else> {{ data.item | pendency }} </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn id="btnCancel" text @click="close"> Cancelar</v-btn>
        <v-btn id="btnConfirm" color="success" :loading="loadingSave" :disabled="!validBtn" @click="acao()">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="acaoSucess" persistent max-width="370">
      <v-card>
        <v-card-title class="text-h5">
          <v-alert dense text type="success">Certidão {{ tipoAcao.toLowerCase() }} com sucesso.</v-alert>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btnOk" color="success" @click="cadastroSucess"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import AdminServices from '@/services/adminServices.js';
import certidaoService from '@/services/certidaoService.js';
import gedService from '@/services/gedService.js';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';

import { eventBus } from '../../../../main.js';

export default {
  name: 'ModalCertidaoNova',
  props: ['certidao', 'editOrNot'],

  data() {
    return {
      validBtn: false,
      valid: false,
      disabled: 'disabled',
      validOrNot: '',
      userEstablishment: [],
      show: false,
      loadingSave: false,
      obrigatorio: 'Obrigatório',
      cnd_file: null,
      pendecy_file: null,
      titulo: '',
      labelBotao: '',
      acaoSucess: false,
      validacaoError: false,
      tipoAcao: '',
      mensagemErro: '',
      isEdit: false,
      tipos: ['CND', 'CPEN', 'Indisponível'],
      //SOLICITADO NA ESTÓRIA AG-272
      //pendencias: ['TRUE', 'FALSE', 'UNAVAILABLE'],
      pendencias: ['TRUE', 'FALSE'],
      mensagemFile: '',
      isCndOrCpen: false,
      rules1: [(value) => !value || value.size < 10485760 || 'O arquivo não pode superar o tamanho de 10MB!'],
      rules2: [(value) => !value || value.size < 10485760 || 'O arquivo não pode superar o tamanho de 10MB!'],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      userInfo: 'user/userInfo',
    }),

    certidaoToEdit() {
      return this.certidao;
    },
  },

  created() {
    this.init();
    this.getCompanies();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('edit', this.edit);
      this.$on('close', this.close);
    },

    async open() {
      this.titulo = 'Adicionar certidão';
      this.labelBotao = 'Salvar';
      this.isEdit = false;
      this.acaoSucess = false;
      this.validacaoError = false;
      this.validBtn = false;
      this.show = true;
    },

    async edit() {
      this.titulo = 'Editar certidão';
      this.labelBotao = 'Alterar';
      this.isEdit = true;
      this.acaoSucess = false;
      this.validacaoError = false;
      this.show = true;
    },

    close() {
      //this.certidaoToEdit = {};
      this.isCndOrCpen = false;
      this.mensagemErro = false;
      this.validBtn = false;
      this.show = false;
    },

    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);

      let userEstablishments = data.map((item) => item.establishment);
      userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);
      // let establishments = userEstablishments.filter(
      //   (v, i, a) => a.findIndex((t) => t.details.socialName === v.details.socialName) === i,
      // );

      //establishments.forEach((item) => (item.details.cnpj = item.details.cnpj.substring(0, 8)));
      //establishments = establishments.map((item) => (item.details.cnpj = item.details.cnpj.substring(0, 8)));

      this.userEstablishment = userEstablishments;
    },

    changeTipo() {
      this.isCndOrCpen = true;
      if (this.certidaoToEdit.certificate_type == 'CND' || this.certidaoToEdit.certificate_type == 'CPEN') {
        this.mensagemFile =
          'Para upload de CND ou pendência é aceito arquivo de extensão .pdf e de tamanho máximo de 10Mb';
      } else {
        this.certidaoToEdit.pendencyType = 'TRUE';
        this.certidaoToEdit.cnd_file = null;
        this.certidaoToEdit.hour_issuance = null;
        this.certidaoToEdit.code_control = null;
        this.certidaoToEdit.expiration_date = null;
        this.mensagemFile = 'Para upload de pendência é aceito arquivo de extensão .pdf e de tamanho máximo de 10Mb';
      }
    },

    validData() {
      if (this.certidaoToEdit.pendencyType == 'FALSE') {
        this.certidaoToEdit.pendecy_file = null;
      }

      let validCnpj = this.certidaoToEdit.cnpj != null && this.certidaoToEdit.cnpj != '';
      let validCertificateType =
        typeof this.certidaoToEdit.certificate_type != 'undefined' &&
        this.certidaoToEdit.certificate_type != null &&
        this.certidaoToEdit.certificate_type != '';
      let validCodeControl = this.certidaoToEdit.code_control != null && this.certidaoToEdit.code_control != '';
      let validTimeIssuance = this.certidaoToEdit.time_issuance != null && this.certidaoToEdit.time_issuance != '';
      let validHourIssuance = this.certidaoToEdit.hour_issuance != null && this.certidaoToEdit.hour_issuance != '';
      let validExpirationDate =
        this.certidaoToEdit.expiration_date != null && this.certidaoToEdit.expiration_date != '';
      let validCndFile = this.certidaoToEdit.cnd_file != null && this.certidaoToEdit.cnd_file != '';
      let validPendencyFile = this.certidaoToEdit.pendecy_file != null && this.certidaoToEdit.pendecy_file != '';
      let validPendencyType = this.certidaoToEdit.pendencyType != null && this.certidaoToEdit.pendencyType != '';

      if (
        this.certidaoToEdit.certificate_type != 'Indisponível' &&
        validCnpj &&
        validCertificateType &&
        validCodeControl &&
        validTimeIssuance &&
        validHourIssuance &&
        validExpirationDate &&
        validCndFile &&
        (validPendencyFile || (!validPendencyFile && this.certidaoToEdit.pendencyType == 'FALSE')) &&
        validPendencyType
      ) {
        this.validBtn = true;
        return true;
      } else if (
        this.certidaoToEdit.certificate_type == 'Indisponível' &&
        validCnpj &&
        validCertificateType &&
        validTimeIssuance &&
        (validPendencyFile || (!validPendencyFile && this.certidaoToEdit.pendencyType == 'FALSE')) &&
        validPendencyType
      ) {
        this.validBtn = true;
        return true;
      } else {
        this.validBtn = false;
        return false;
      }
    },

    async acao() {
      this.validacaoError = false;
      this.mensagemErro = '';

      if (this.certidaoToEdit.cnpj == null || this.certidaoToEdit.cnpj == '') {
        this.mensagemErro = 'Selecione um CNPJ';
        this.validacaoError = true;
        return;
      }
      if (this.certidaoToEdit.certificate_type == null || this.certidaoToEdit.certificate_type == '') {
        this.mensagemErro = 'Selecione um tipo';
        this.validacaoError = true;
        return;
      }

      if (
        this.certidaoToEdit.time_issuance == null ||
        this.certidaoToEdit.time_issuance == '' ||
        (this.certidaoToEdit.time_issuance &&
          this.certidaoToEdit.time_issuance != '' &&
          !this.validarData(this.certidaoToEdit.time_issuance))
      ) {
        if (this.certidaoToEdit.time_issuance == null || this.certidaoToEdit.time_issuance == '') {
          this.mensagemErro =
            this.certidaoToEdit.certificate_type == 'Indisponível'
              ? 'Preencha uma data da tentativa.'
              : 'Preencha uma data de emissão.';
          this.validacaoError = true;
          return;
        } else if (
          this.certidaoToEdit.time_issuance &&
          this.certidaoToEdit.time_issuance != '' &&
          !this.validarData(this.certidaoToEdit.time_issuance)
        ) {
          this.mensagemErro =
            this.certidaoToEdit.certificate_type == 'Indisponível'
              ? 'Preencha com uma data valida o campo data da tentativa.'
              : 'Preencha com uma data valida o campo data de emissão.';
          this.validacaoError = true;
          return;
        }
      }

      if (this.certidaoToEdit.pendencyType == null || this.certidaoToEdit.pendencyType == '') {
        this.mensagemErro = 'Selecione a situação';
        this.validacaoError = true;
        return;
      }

      if (
        (this.certidaoToEdit.certificate_type == 'Indisponível' && this.certidaoToEdit.pendencyType != 'FALSE') ||
        (this.certidaoToEdit.certificate_type != 'Indisponível' && this.certidaoToEdit.pendencyType != 'FALSE')
      ) {
        if (
          (this.certidaoToEdit.pendecy_file == null || this.certidaoToEdit.pendecy_file == '') &&
          (this.certidaoToEdit.id_pendency_file == null || this.certidaoToEdit.id_pendency_file == '')
        ) {
          this.mensagemErro = 'Adicione um arquivo de pendência';
          this.validacaoError = true;
          return;
        }
      }

      if (this.certidaoToEdit.certificate_type != 'Indisponível') {
        if (
          this.certidaoToEdit.hour_issuance == null ||
          this.certidaoToEdit.hour_issuance == '' ||
          (this.certidaoToEdit.hour_issuance &&
            !this.certidaoToEdit.hour_issuance == '' &&
            !this.validarHora(this.certidaoToEdit.hour_issuance))
        ) {
          this.mensagemErro = 'Preencha uma hora de emissão';
          this.validacaoError = true;
          return;
        }

        if (
          this.certidaoToEdit.expiration_date == null ||
          (this.certidaoToEdit.expiration_date &&
            !this.certidaoToEdit.expiration_date == '' &&
            !this.validarData(this.certidaoToEdit.expiration_date))
        ) {
          this.mensagemErro = 'Preencha uma data de validade';
          this.validacaoError = true;
          return;
        }

        if (this.certidaoToEdit.certificate_type == 'CND' || this.certidaoToEdit.certificate_type == 'CPEN') {
          if (this.certidaoToEdit.code_control.length < 19) {
            this.mensagemErro = 'Código de controle deve conter 16 caracteres alfanuméricos';
            this.validacaoError = true;
            return;
          }
          if (this.certidaoToEdit.id_cnd_file == null || this.certidaoToEdit.id_cnd_file == '') {
            if (this.certidaoToEdit.code_control == null || this.certidaoToEdit.code_control == '') {
              this.mensagemErro = 'Preencha um código';
              this.validacaoError = true;
              return;
            }
            if (
              this.certidaoToEdit.hour_issuance == null ||
              this.certidaoToEdit.hour_issuance == '' ||
              (this.certidaoToEdit.hour_issuance &&
                !this.certidaoToEdit.hour_issuance == '' &&
                !this.validarHora(this.certidaoToEdit.hour_issuance))
            ) {
              this.mensagemErro = 'Preencha uma hora da emissão';
              this.validacaoError = true;
              return;
            }
            if (
              this.certidaoToEdit.expiration_date == null ||
              (this.certidaoToEdit.expiration_date &&
                !this.certidaoToEdit.expiration_date == '' &&
                !this.validarData(this.certidaoToEdit.expiration_date))
            ) {
              this.mensagemErro = 'Preencha uma data de validade';
              this.validacaoError = true;
              return;
            }
            if (this.certidaoToEdit.cnd_file == null || this.certidaoToEdit.cnd_file == '') {
              this.mensagemErro = 'Adicione um arquivo de certidão';
              this.validacaoError = true;
              return;
            }
            if (!this.validarData(this.certidaoToEdit.time_issuance)) {
              this.mensagemErro = 'Preencha uma data de emissão';
              this.validacaoError = true;
              return;
            }
            if (!this.validarHora(this.certidaoToEdit.hour_issuance)) {
              this.mensagemErro = 'Preencha uma hora da emissão';
              this.validacaoError = true;
              return;
            }
            if (!this.validarData(this.certidaoToEdit.expiration_date)) {
              this.mensagemErro = 'Preencha uma data de validade';
              this.validacaoError = true;
              return;
            }
          }
        }

        if (this.validaDataEmissaoValidade()) {
          this.mensagemErro = 'Data de validade não pode ser anterior a data de emissão';
          this.validacaoError = true;
          return;
        }
      }

      this.loadingSave = true;
      let certidao = await this.montarRequestCertidao();

      if (!this.validacaoError) {
        this.isCndOrCpen = false;
        if (this.isEdit) {
          certidao.id_certificate = this.certidaoToEdit.id_certificate;
          this.alterar(certidao);
        } else {
          this.salvar(certidao);
        }
      }
    },

    validaDataEmissaoValidade() {
      if (this.certidaoToEdit.expiration_date) {
        var dataValidade = this.convertData(this.certidaoToEdit.expiration_date);
      }
      var dataEmissao = this.convertData(this.certidaoToEdit.time_issuance);
      return dataValidade < dataEmissao;
    },

    convertData(data) {
      const [diaT, mesT, anoT] = data.split('/');
      const dia = parseInt(diaT);
      const mes = parseInt(mesT);
      const ano = parseInt(anoT);
      var dataConvertida;

      try {
        dataConvertida = new Date(ano, mes, dia);
      } catch (e) {
        return false;
      }
      return dataConvertida;
    },

    validarHora(horas) {
      const [horaT, minutoT, segundoT] = horas.split(':');
      let hora = parseInt(horaT); //parseInt(horaT);
      let minutos = parseInt(minutoT);
      let segundos = parseInt(segundoT);

      if (hora >= 24) {
        this.mensagemErro = 'Preencha uma hora da emissão';
        this.validacaoError = true;
        return false;
      }
      if (minutos > 59) {
        this.mensagemErro = 'Preencha uma hora da emissão';
        this.validacaoError = true;
        return false;
      }
      if (segundos > 59) {
        this.mensagemErro = 'Preencha uma hora da emissão';

        return false;
      }
      return true;
    },

    validarData(data) {
      const [diaT, mesT, anoT] = data.split('/');
      const dia = parseInt(diaT);
      const mes = parseInt(mesT);
      const ano = parseInt(anoT);

      try {
        new Date(ano, mes, dia);
      } catch (e) {
        return false;
      }

      switch (mes) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (dia <= 31) {
            return true;
          } else {
            return false;
          }
        case 4:
        case 6:
        case 9:
        case 11:
          if (dia <= 30) {
            return true;
          } else {
            return false;
          }
        case 2:
          if (ano % 400 == 0 || (ano % 4 == 0 && ano % 100 != 0))
            if (dia <= 29) {
              return true;
            } else {
              return false;
            }
          else if (dia <= 28) {
            return true;
          } else {
            return false;
          }
      }
    },

    async salvar(certidao) {
      this.tipoAcao = 'Salva';
      let response = '';
      try {
        response = await certidaoService.certidoes.post(certidao);
        if (response.data.id_certificate) {
          this.acaoSucess = false;
        } else {
          this.acaoError = true;
        }
        this.loadingSave = false;
        this.showToast(`Certidão adicionada com sucesso!`, 'success', 78);
        this.close();
        this.$emit('refresh');
      } catch (e) {
        this.loadingSave = false;

        this.mensagemErro = e.response.data.message.split(':')[1];
        this.validacaoError = true;
      }
    },

    cadastroSucess() {
      this.acaoSucess = false;
      this.close();
    },

    async alterar(certidao) {
      this.tipoAcao = 'alterada';
      let response = await certidaoService.certidoes.put(certidao);
      if (response.data.id_certificate) {
        this.acaoSucess = true;
      } else {
        this.acaoError = true;
      }
      this.loadingSave = false;
      this.showToast(`Alterações realizadas com sucesso!`, 'success', 78);
    },

    async montarRequestCertidao() {
      let certidao = {};
      certidao.time_issuance = new Date(this.formatarDateUSIssuanse()).getTime();
      if (this.certidaoToEdit.certificate_type == 'CND' || this.certidaoToEdit.certificate_type == 'CPEN') {
        certidao.expiration_date = new Date(this.formatarDateUSExpiration()).getTime();
        certidao.code_control = this.certidaoToEdit.code_control;
        //arquivo cnd
        if (this.isEdit && this.certidaoToEdit.id_cnd_file && !this.certidaoToEdit.cnd_file) {
          certidao.id_cnd_file = this.certidaoToEdit.id_cnd_file;
        } else {
          let base64 = await this.obterArquivo(this.certidaoToEdit.cnd_file);
          if (base64.split(';')[0] !== 'data:application/pdf') {
            this.mensagemErro = 'Tipo de documento CND inválido, informe do tipo .pdf';
            this.validacaoError = true;
            this.loadingSave = false;
            return;
          }
          base64 = base64.split(',')[1];
          certidao.id_cnd_file = await this.uploadArquivo(this.certidaoToEdit.cnd_file, base64);
        }
      }

      let nomeEstablishment = this.userEstablishment.find((e) => {
        if (e.details.cnpj.substring(0, 8) === this.certidaoToEdit.cnpj.substring(0, 8)) {
          return e.socialName;
        }
      });

      certidao.name_establishment = nomeEstablishment.socialName;
      certidao.cnpj = this.certidaoToEdit.cnpj;
      certidao.certificate_type = this.certidaoToEdit.certificate_type;
      certidao.pendencyType = this.certidaoToEdit.pendencyType;

      if (this.certidaoToEdit.pendencyType != 'FALSE' && this.certidaoToEdit.pendecy_file != null) {
        //arquivo pendencia
        if (this.isEdit && this.certidaoToEdit.id_pendency_file && !this.certidaoToEdit.pendecy_file) {
          certidao.id_pendency_file = this.certidaoToEdit.id_pendency_file;
        } else {
          let base64Pendency = await this.obterArquivo(this.certidaoToEdit.pendecy_file);

          if (base64Pendency.split(';')[0] !== 'data:application/pdf') {
            this.mensagemErro = 'Tipo de documento para pendência inválido, informe do tipo .pdf';
            this.validacaoError = true;
            this.loadingSave = false;
            return;
          }
          base64Pendency = base64Pendency.split(',')[1];
          certidao.id_pendency_file = await this.uploadArquivo(this.certidaoToEdit.pendecy_file, base64Pendency);
        }
      }
      return certidao;
    },

    async uploadArquivo(file, arquivo) {
      let fileToUpload = {
        name: file.name,
        user: this.userInfo.username,
        estab: this.certidaoToEdit.cnpj,
        company: this.certidaoToEdit.cnpj.substring(0, 8),
        userid: this.userInfo.id,
        clienteid: 'web-front-end',
        visibility: true,
        base64: arquivo,
      };

      let responseGed = await gedService.files.post(fileToUpload);
      return responseGed.data;
    },

    obterArquivo(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    formatarDateUSIssuanse() {
      const [dia, mes, ano] = this.certidaoToEdit.time_issuance.split('/');
      if (this.certidaoToEdit.certificate_type == 'Indisponível') {
        const horaAtual = new Date().toLocaleTimeString('en-US', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return ano + '-' + mes + '-' + dia + 'T' + horaAtual + '.000';
      } else {
        return ano + '-' + mes + '-' + dia + 'T' + this.certidaoToEdit.hour_issuance + '.000';
      }
    },

    formatarDateUSExpiration() {
      const [dia, mes, ano] = this.certidaoToEdit.expiration_date.split('/');
      return ano + '-' + mes + '-' + dia + 'T00:00:00.000';
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style>
.tooltipUnavailable {
  width: 192px;
  height: 48px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;

  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
}

.indisponivel-style .v-input__slot {
    background-color: #e7e7fa !important;
    height: 40px;
    border: 0px solid #CCCCCC;
}
</style>
