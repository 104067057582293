var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "div",
            { attrs: { centered: "" } },
            [
              _c("v-card-title", [
                _c(
                  "span",
                  { staticClass: "text-h5", staticStyle: { color: "#182026" } },
                  [_vm._v(_vm._s(_vm.titulo))]
                ),
              ]),
              _c(
                "v-alert",
                {
                  attrs: {
                    dismissible: "",
                    dense: "",
                    text: "",
                    type: "error",
                  },
                  model: {
                    value: _vm.validacaoError,
                    callback: function ($$v) {
                      _vm.validacaoError = $$v
                    },
                    expression: "validacaoError",
                  },
                },
                [_vm._v(_vm._s(_vm.mensagemErro))]
              ),
              _c(
                "v-alert",
                {
                  attrs: {
                    dismissible: "",
                    dense: "",
                    text: "",
                    type: "warning",
                  },
                  model: {
                    value: _vm.isCndOrCpen,
                    callback: function ($$v) {
                      _vm.isCndOrCpen = $$v
                    },
                    expression: "isCndOrCpen",
                  },
                },
                [_vm._v(_vm._s(_vm.mensagemFile))]
              ),
              _c("div", { attrs: { "text-align": "center" } }, [
                _c(
                  "div",
                  { staticClass: "pa-5 form-group" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _vm.isEdit
                              ? _c(
                                  "v-col",
                                  { attrs: { sm: "5" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        id: "textCnpj",
                                        disabled: "",
                                        label: "CNPJ",
                                        outlined: "",
                                        "persistent-placeholder": "",
                                        dense: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.validData()
                                        },
                                      },
                                      model: {
                                        value: _vm.certidaoToEdit.cnpj,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.certidaoToEdit,
                                            "cnpj",
                                            $$v
                                          )
                                        },
                                        expression: "certidaoToEdit.cnpj",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isEdit
                              ? _c(
                                  "v-col",
                                  { attrs: { sm: "5" } },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "static",
                                      attrs: {
                                        id: "idACCnpj",
                                        label: "CNPJ",
                                        items: _vm.userEstablishment,
                                        "item-text": "details.cnpj",
                                        "item-value": (item) =>
                                          `${item.details.cnpj}`,
                                        multiple: false,
                                        "append-icon": "mdi-chevron-down",
                                        dense: "",
                                        "hide-details": "",
                                        outlined: "",
                                        "persistent-placeholder": "",
                                        placeholder: _vm.obrigatorio,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.validData()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function ({ index }) {
                                              return [
                                                index === 0
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("cnpj")(
                                                              _vm.certidaoToEdit
                                                                .cnpj
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function ({ item, attrs, on }) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        class: {
                                                          "pl-10": item.group,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                active,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "input-value":
                                                                                active,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "cnpj"
                                                                              )(
                                                                                item
                                                                                  .details
                                                                                  .cnpj
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.socialName
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      "v-list-item",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2974923656
                                      ),
                                      model: {
                                        value: _vm.certidaoToEdit.cnpj,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.certidaoToEdit,
                                            "cnpj",
                                            $$v
                                          )
                                        },
                                        expression: "certidaoToEdit.cnpj",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { sm: "3" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "static",
                                  attrs: {
                                    id: "idACTipe",
                                    label: "Tipo",
                                    items: _vm.tipos,
                                    "item-value": (item) => `${item}`,
                                    multiple: false,
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    "append-icon": "mdi-chevron-down",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                    blur: function ($event) {
                                      return _vm.changeTipo()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.certificate_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "certificate_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "certidaoToEdit.certificate_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "XXXX.XXXX.XXXX.XXXX",
                                      expression: "'XXXX.XXXX.XXXX.XXXX'",
                                    },
                                  ],
                                  style:
                                    _vm.certidaoToEdit.certificate_type ==
                                    "Indisponível"
                                      ? "background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;"
                                      : "background-color: #ffffff; height: 40px !important;",
                                  attrs: {
                                    id: "textCodigo",
                                    maxlength: "19",
                                    minlength: "19",
                                    counter: 19,
                                    label: "Código de controle",
                                    dense: "",
                                    outlined: "",
                                    disabled:
                                      _vm.certidaoToEdit.certificate_type ==
                                      "Indisponível",
                                    "persistent-placeholder": "",
                                    placeholder: "obrigatorio",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.code_control,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "code_control",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.code_control",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/##/####",
                                      expression: "'##/##/####'",
                                    },
                                  ],
                                  attrs: {
                                    maxlength: "10",
                                    minlength: "10",
                                    counter: 10,
                                    label:
                                      _vm.certidaoToEdit.certificate_type ==
                                      "Indisponível"
                                        ? "Data da tentativa"
                                        : "Data da emissão",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.time_issuance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "time_issuance",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.time_issuance",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##:##:##",
                                      expression: "'##:##:##'",
                                    },
                                  ],
                                  style:
                                    _vm.certidaoToEdit.certificate_type ==
                                    "Indisponível"
                                      ? "background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;"
                                      : "background-color: #ffffff; height: 40px !important;",
                                  attrs: {
                                    maxlength: "8",
                                    minlength: "8",
                                    counter: 8,
                                    label: "Hora da emissão",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                    disabled:
                                      _vm.certidaoToEdit.certificate_type ==
                                      "Indisponível",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.hour_issuance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "hour_issuance",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.hour_issuance",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/##/####",
                                      expression: "'##/##/####'",
                                    },
                                  ],
                                  style:
                                    _vm.certidaoToEdit.certificate_type ==
                                    "Indisponível"
                                      ? "background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;"
                                      : "background-color: #ffffff; height: 40px !important;",
                                  attrs: {
                                    maxlength: "10",
                                    minlength: "10",
                                    counter: 10,
                                    label: "Validade",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                    disabled:
                                      _vm.certidaoToEdit.certificate_type ==
                                      "Indisponível",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.expiration_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "expiration_date",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "certidaoToEdit.expiration_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-file-input", {
                                  class: {
                                    "indisponivel-style":
                                      _vm.certidaoToEdit.certificate_type ===
                                      "Indisponível",
                                  },
                                  attrs: {
                                    rules: _vm.rules1,
                                    label: "Upload da CND",
                                    accept: "application/pdf",
                                    outlined: "",
                                    dense: "",
                                    disabled:
                                      _vm.certidaoToEdit.certificate_type ==
                                      "Indisponível",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.cnd_file,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "cnd_file",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.cnd_file",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-file-input", {
                                  class: {
                                    "indisponivel-style":
                                      _vm.certidaoToEdit.pendencyType ==
                                      "FALSE",
                                  },
                                  attrs: {
                                    rules: _vm.rules2,
                                    label: "Upload de pendência",
                                    accept: "application/pdf",
                                    outlined: "",
                                    dense: "",
                                    disabled:
                                      _vm.certidaoToEdit.pendencyType ==
                                      "FALSE",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  model: {
                                    value: _vm.certidaoToEdit.pendecy_file,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "pendecy_file",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.pendecy_file",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "4" } },
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    items: _vm.pendencias,
                                    label: "Pendências",
                                    dense: "",
                                    outlined: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validData()
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("pendency")(data.item)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          data.item === "UNAVAILABLE"
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    right: "",
                                                    color: "grey darken-2",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {},
                                                                  "span",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "pendency"
                                                                      )(
                                                                        data.item
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltipUnavailable",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "O plano contratado desta empresa "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " não contempla a funcionalidade de "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " análise de pendências"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("pendency")(
                                                        data.item
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.certidaoToEdit.pendencyType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certidaoToEdit,
                                        "pendencyType",
                                        $$v
                                      )
                                    },
                                    expression: "certidaoToEdit.pendencyType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { id: "btnCancel", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btnConfirm",
                    color: "success",
                    loading: _vm.loadingSave,
                    disabled: !_vm.validBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.acao()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.labelBotao) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "370" },
          model: {
            value: _vm.acaoSucess,
            callback: function ($$v) {
              _vm.acaoSucess = $$v
            },
            expression: "acaoSucess",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5" },
                [
                  _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "success" } },
                    [
                      _vm._v(
                        "Certidão " +
                          _vm._s(_vm.tipoAcao.toLowerCase()) +
                          " com sucesso."
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "btnOk", color: "success" },
                      on: { click: _vm.cadastroSucess },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }